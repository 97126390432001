import chief from "../../../images/chief.jpg";

const ChiefEditor = () => {
  return (
    <div className="my-12 w-[70%] mx-auto">
        <h1 className="text-4xl font-bold text-center underline underline-offset-8 mt-5 mb-12">Chief Editor</h1>
      <img className="w-[30%] h-[auto] mx-auto" src={chief} alt="chief" />{" "}
      <br />
      <p className="text-[18px] mb-3 w-[100%]">
        Dr. Md Ashraful Alam is currently working as an Associate Professor at
        North South University, Bangladesh. Dr. Alam completed his doctoral
        study in the School of Biomedical Sciences, at The University of
        Queensland, Australia. For his earlier education, Dr. Alam obtained his
        B. Pharm from Khulna University, and MS in Pharmacology from Bangladesh
        Agricultural University. Dr. Alam has diverse research experience in
        phytochemistry and pharmacology, especially in the field of obesity,
        cardiovascular complications and redox signaling in biological system.
        During his PhD, Dr. Alam contributed in the development of high fat diet
        fed obese animal model in rats and studied the efficacy of natural
        antioxidants in treating obesity and cardiac disorder. He is an expert
        with the Langendorff heart function, echocardiography, mitochondrial
        electron transport chain analysis, western blot, confocal and
        fluorescence microscopy, biochemical analysis for plasma and other
        tissues, and histological staining. During his PhD, he published eight
        articles as a first author. Dr. Alam has mentored over 100 undergraduate
        B. Pharm and M. Pharm students and published over 120 research articles
        in various prestigious journals in BMC, Wiley, Nature-Springer,
        Elsevier, and Taylor and Francis. His works are cited more than 5000
        times till date. A good number of students are now pursuing their
        Masters or PhD in USA, Germany, Australia. Based on his research
        performance, he was awarded Dean’s Award, 2016 and Best Researcher
        Award, 2021 from North South University, Bangladesh. Dr. Alam and his
        students presented posters at the different national and international
        conferences, and many of them received the best presentation awards.
      </p>
      <p className="text-[18px] mb-3 w-[100%]">
        <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://www.researchgate.net/profile/Md-Alam-44">Visit the ResearchGate</a>
        </p>

        <p className="text-[18px] mb-3 w-[100%]">
        <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://scholar.google.com/citations?user=EHigr8EAAAAJ&amp;hl=en">Visit the Google Scholar</a>
        </p>
    </div>
  );
};

export default ChiefEditor;
