import ArticleNav from "../../Shared/ArticleNav/ArticleNav";
import book2 from "../../images/book-2.jpg";
import book3 from "../../images/book-3.jpg";

const Forthcoming = () => {
  return (
    <section>
      <ArticleNav></ArticleNav>
      <div className=" max-w-[80%] md:max-w-[90%] mx-auto">
        {/* <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Current Issue</h2>
          </div>

          <figure className="px-10">
            <img src={book} alt="book" className="rounded-xl" />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Vol 1- Issue 1</h2>
          </div>
        </div> */}
        <div className="card bg-base-100 shadow-sm my-10">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Forthcoming Issue</h2>
          </div>

          <figure className="px-10">
            <img
              src={book2}
              alt="book"
              className="w-[50%] h-[auto] rounded-xl"
            />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Vol 2- Issue 1</h2>
          </div>
        </div>
        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Forthcoming Issue</h2>
          </div>

          <figure className="px-10">
            <img
              src={book3}
              className="w-[50%] h-[auto] rounded-xl"
              alt="book"
            />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Vol 2- Issue 2</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Forthcoming;
