import ArticleNav from "../../Shared/ArticleNav/ArticleNav";
import book from "../../images/book.png";

const Allissue = () => {
  return (
    <section>
      <ArticleNav></ArticleNav>
      <div className=" max-w-[80%] md:max-w-[90%] mx-auto my-10">
        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Special Issue</h2>
          </div>

          <figure className="px-10">
            <img
              src={book}
              alt="book"
              className="w-[50%] h-[auto] rounded-xl"
            />
          </figure>
          <div className="card-body items-center text-center">
            {/* <h2 className="card-title">Vol 1- Issue 1</h2> */}
          </div>
        </div>

        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Current Issue</h2>
          </div>

          <figure className="px-10">
            <img
              src={book}
              alt="book"
              className="w-[50%] h-[auto] rounded-xl"
            />
          </figure>
          <div className="card-body items-center text-center">
            <a
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              href="https://drive.google.com/file/d/18CbdRDZXfeiKl0xEnVNs-8xD4O5b5F-d/view?usp=sharing"
            >
              {" "}
              <h2 className="card-title">Vol 1 - Issue 1</h2>
            </a>
          </div>
        </div>
        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Forthcoming Issue</h2>
          </div>

          <figure className="px-10">
            <img
              src={book}
              alt="book"
              className="w-[50%] h-[auto] rounded-xl"
            />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Vol 2- Issue 1</h2>
          </div>
        </div>
        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Forthcoming Issue</h2>
          </div>

          <figure className="px-10">
            <img
              src={book}
              className="w-[50%] h-[auto] rounded-xl"
              alt="book"
            />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Vol 2- Issue 2</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Allissue;
