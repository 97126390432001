import React from 'react';

const Special = () => {
    return (
        <div>
            <div className="text-3xl">Special Issue</div>
        </div>
    );
};

export default Special;