import "./Article.css";
import img1 from "../../../../images/img-1.jpg";
import { Link } from "react-router-dom";
import ArticleNav from "../../../../Shared/ArticleNav/ArticleNav";

const Article = ({ article }) => {
  const { id, title, author, publishInfo,url } = article;

  return (
    <div className="article">
      <div className="article-area">
        <div className="leftside shadow-lg bg-white">
          <div>
            <div className="my-1">
              <span className="border-solid border-[1px] rounded border-black p-1 text-xs">
                Original article
              </span>
            </div>
            <div>
              <Link
                to={`articleDetails/${id}`}
                className=" artTitle text-xl font-semibold hover:underline hover:text-[#C34A36]"
              >
                {title}
              </Link>
            </div>

            <div className="text-sm text-[#C34A36] my-1">
              <span>{author}</span>
            </div>

            <div className="artInfo">
              <span> {publishInfo} </span>
            </div>

            <div className="artAccess">
              <span>
              <a
                  className="flex items-center gap-1 hover:text-[#C34A36] hover:underline transition-colors duration-500"
                  href={url}
                >
                  {" "}
                  Read the full Article
                </a>
              </span>
              <span>
                <a
                  className="flex items-center gap-1 hover:text-[#C34A36] hover:underline transition-colors duration-500"
                  href={url}
                >
                  {" "}
                  Download PDF
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Article;
