import React, { useEffect, useState } from "react";

const EditorialBoard = () => {
  return (
    <div className="max-w-[90%] mx-auto my-10">
      <div className="text-xl font-semibold text-center mt-5">
        Editorial Board
      </div>

      <></>

      <div className="text-center mt-10">
        <div className="flex flex-col justify-center items-center">
          <p className="text-lg text-center font-semibold mt-5 mb-[2px] underline underline-offset-2">
            Chief Editor
          </p>
          <h3 className="text-lg text-center">Dr. Md. Ashraful Alam</h3>
          <p className="text-sm text-center">
            Associate Professor, <br />
            Department of Pharmaceutical Sciences, <br /> North South University
          </p>
          <p className="text-center">
            <strong>Email: </strong>ashraful.alam@northsouth.edu, <br />{" "}
            journalbep@gmail.com
          </p>
        </div>

        <div className="flex flex-col justify-center items-center">
          <p className="text-lg font-semibold mt-5 mb-[2px] underline underline-offset-2">
            Executive Editor
          </p>
          <h3 className="text-lg text-center">Dr. Ferdous Khan</h3>
          <p className="text-sm text-center">
            Associate Professor, <br />
            Department of Pharmaceutical Sciences, <br /> North South University
          </p>
          <p className="text-center">
            <strong>Email: </strong>khan.ferdous@northsouth.edu
          </p>
        </div>
      </div>

      <section className="max-w-[70%] mx-auto">
        <div className="text-xl font-semibold text-center my-10 underline underline-offset-2">
          Editors Panel
        </div>
        <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Muhammad Maqsud Hossain</h3>
            <p>Associate Professor </p>
            <p>
              Department of Biochemistry and Microbiology North South
              University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              muhammad.maqsud@northsouth.edu
            </p>
          </div>
          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Iqbal Ahmed</h3>
            <p>Professor</p>
            <p>Pharmacy Discipline, Khulna University</p>
            <p>
              <strong>Email: </strong>
              i.ahmed@pharm.ku.ac.bd
            </p>
          </div>
          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Mahbubur Rahman</h3>
            <p>Associate Professor</p>
            <p>
              Department of Pharmaceutical Sciences, North South University,
              Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              rahman.mahbubur@northsouth.edu
            </p>
          </div>
          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Mohammad Hossain Shariare</h3>
            <p>Professor</p>
            <p>
              Department of Pharmaceutical Sciences, North South University,
              Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              mohammad.shariare@northsouth.edu
            </p>
          </div>
          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Md Nazmul Hasan</h3>
            <p>Professor</p>
            <p>
              Department of Genetic Engineering and Biotechnology, Jashore
              University of Science and Technology
            </p>
            <p>
              <strong>Email: </strong>
              mn.hasan@just.edu.bd
            </p>
          </div>
          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Mahmudul Hasan Sikder</h3>
            <p>Professor</p>
            <p>
              Department of Pharmacology, Bangladesh Agricultural University,
              Mymensingh
            </p>
            <p>
              <strong>Email: </strong>
              drmsikder@bau.edu.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Sk. Jamal Uddin</h3>
            <p>Professor</p>
            <p>Pharmacy Discipline, Khulna University, Bangladesh</p>
            <p>
              <strong>Email: </strong>
              jamalown@yahoo.com
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Hemayet Hossain</h3>
            <p>Principle Scientific Officer</p>
            <p>
              Chemical Research Division, Bangladesh Council of Scientific and
              Industrial Research (BCSIR), Dhaka-1205, Bangladesh.
            </p>
            <p>
              <strong>Email: </strong>
              hemayet.hossain02@gmail.com
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Mohammad Rashedul Haque</h3>
            <p>Professor</p>
            <p>
              Department of Pharmaceutical Chemistry, Dhaka University,
              Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              haquemr@du.ac.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Nasrin Akter</h3>
            <p>Assistant Professor</p>
            <p>Department of Pharmacy, Independent University, Bangladesh</p>
            <p>
              <strong>Email: </strong>
              nasrin.a@iub.edu.bd
            </p>
          </div>
          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Sajid Md. Sarker</h3>
            <p>Associate Professor</p>
            <p>
              Department of Pharmaceutical Sciences, North South University,
              Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              shazid.sharker@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Md. Rashedul Islam</h3>

            <p>
              Department of Pharmaceutical Technology, Dhaka University,
              Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              rashedulislam@du.ac.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Md. Amirul Islam</h3>
            <p> Professor</p>
            <p>Department of Pharmacy, East West University, Bangladesh</p>
            <p>
              <strong>Email: </strong>
              amirul.islam@ewubd.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Md. Tanvir Rahman</h3>
            <p>
              Department of Microbiology and Hygiene, Bangladesh Agricultural
              University, Mymensingh.
            </p>

            <p>
              <strong>Email: </strong>
              tanvirahman@bau.edu.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Raushan Ara Akter</h3>
            <p>Professor.</p>
            <p>Department of Pharmacy , Brac University</p>
            <p>
              <strong>Email: </strong>
              pr.ansari@iub.edu.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Professor Sohel Rana</h3>
            <p>Professor.</p>
            <p>
              Department of Pharmacy, Jahangir Nagar University, Savar, Dhaka.{" "}
            </p>
            <p>
              <strong>Email: </strong>
              sohelrana.ju@juniv.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Sharmind Nilotpol </h3>
            <p>Professor.</p>
            <p>Department of Pharmacy, Brac University </p>
            <p>
              <strong>Email: </strong>
              sharmind@bracu.ac.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Elias Al Mamun </h3>
            <p>Professor.</p>
            <p>
              Department of Pharmaceutical Technology, Dhaka University,
              Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              elias_0276@yahoo.com
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Muhammad Torequl Islam </h3>
            <p>
              Department of Pharmacy Bangabandhu Sheikh Mujibur Rahman Science
              and Technology University
            </p>
            <p>
              <strong>Email: </strong>
              dmt.islam@bsmrstu.edu.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Omar Faruk </h3>
            <p>Professor.</p>
            <p>
              Department of Nutrition and Food Technology Jashore Science and
              Technology University
            </p>
            <p>
              <strong>Email: </strong>
              faruquenft@just.edu.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Raquibul Hasan </h3>
            <p>Assistant Professor </p>
            <p>
              Department of Pharmaceutical Sciences Mercer University 3001
              Mercer University Drive, Suite 114 Atlanta, GA 30341
            </p>
            <p>
              <strong>Email: </strong>
              hasan_r@mercer.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Anayet Ullah </h3>

            <p>Tokoshuba University, Japan</p>
            <p>
              <strong>Email: </strong>
              anayatullah1988@gmail.com
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Vaskar Das </h3>
            <p>Assistant Professor</p>
            <p>Department of Anesthesiology RUSH, Chicago, USA</p>
            <p>
              <strong>Email: </strong>
              vaskardas76@gmail.com
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Pritesh Ranjan Dash </h3>
            <p>Lecturer</p>
            <p>Department of Pharmacy, USTC, Bangladesh </p>
            <p>
              <strong>Email: </strong>
              pritesh@ustc.ac.bd
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Sohidul Islam </h3>
            <p>Professor</p>
            <p>
              Department of Biochemistry and Microbiology,North South
              University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              sohidul.islam@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Mohammed Kabir Uddin </h3>
            <p>Assistant Professor </p>
            <p>
              Department of Biochemistry and Microbiology North South
              University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              mohammed.uddin11@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Md Firoz Khan </h3>
            <p>Associate Professor </p>
            <p>Department of Environment Science and Management</p>
            <p>
              <strong>Email: </strong>
              firoz.khan@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Dipak Kumar Mitra</h3>
            <p>Professor </p>
            <p>
              Department of Public Health North South University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              dipak.mitra@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Mohammad Hayatun Nabi </h3>
            <p>Assistant Professor</p>
            <p>
              Department of Public Health North South University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              mohammad.nabi@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Mohammad Delwer Hossain Hawlader </h3>
            <p>Assistant Professor </p>
            <p>
              Department of Public Health North South University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>
              mohammad.hawlader@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. Md Fakruddin</h3>
            <p>Assistant Professor </p>
            <p>
              Department of Biochemistry and Microbiology North South
              University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>md.fakruddin@northsouth.edu
            </p>
          </div>

          <div className="w-[] rounded-md shadow-lg p-5 bg-white">
            <h3>Dr. SM Bakhtiar Ul Islam </h3>
            <p>Assistant Professor</p>
            <p>
              Department of Biochemistry and Microbiology North South
              University, Bangladesh
            </p>
            <p>
              <strong>Email: </strong>bakhtiar.islam@northsouth.edu 
            </p>
          </div>
        </div>

        <div></div>

        <div className="text-center my-10 flex flex-col justify-center items-center">
          <p className="text-lg font-semibold mt-5 mb-[2px] underline underline-offset-2">
            Publisher
          </p>
          <h3 className="text-lg">Dr. Nusrat Subhan</h3>
          <p className="text-sm">
            <strong>Email: </strong>rimmi04@yahoo.com
          </p>

          <h1 className="my-1">
            Publishing House- Dr. Nusrat Subhan and Son’s Science Publisher
          </h1>
          <h2>Journal of Biosciences and Experimental Pharmacology</h2>
        </div>
      </section>
    </div>
  );
};

export default EditorialBoard;
