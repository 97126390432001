import React from "react";

const Indexing = () => {
  return (
    <div>
      <div className="text-3xl text-center mt-5">Indexing</div>
      <div className="text-4xl py-10 text-center h-[500px] flex items-center justify-center">
        Page is under construction
      </div>
    </div>
  );
};

export default Indexing;
