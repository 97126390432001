import React from "react";
import { useLoaderData } from "react-router-dom";
import book from "../../../../images/book.png";
import book2 from "../../../../images/book-2.jpg";
import book3 from "../../../../images/book-3.jpg";
import "../ArticleDetails/ArticleDetails.css";

const ArticleDetailscopy = () => {
  const data = useLoaderData();
  console.log(data);
  const { id, title, author, publishInfo, description } = data;

  return (
    <div className="grid md:grid-cols-7 gap-y-5 gap-x-5 max-w-[100%] mx-auto p-6 mt-5">
      <div className="md:col-span-5 bg-white rounded-lg p-5">
        <div className="max-w-[95%] mx-auto">
          <div className="topmeta">
            <span>Original article</span>| <span>Open access</span> |
            <span> {publishInfo}</span>|
            {/* <a className="hover:underline" href="" target="_blank">doi: 10.5455/jabet.2023.d157</a> */}
          </div>
          <span className="">
            <h2 className="text-3xl font-semibold max-w-[80%] my-3">{title}</h2>

            <p className="text-sm mb-2">
              Tuli Samayeta Sarkar, Shehreen Saadlee, Khan Abira, Akhteruzzaman
              Sharif, Sajib Abu Ashfaqur{" "}
            </p>
          </span>

          <div className="bg-[#DDE2E6] max-w-[100%] rounded-lg p-5">
            <h2 className="text-2xl font-semibold mb-4">Abstract</h2>
            <p className="text-lg">{description}</p>
          </div>
        </div>
      </div>

      {/* ********************* */}

      <div className="md:col-span-2 max-w-[80%] md:max-w-[90%] mx-auto">
        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Current Issue</h2>
          </div>

          <figure className="px-10">
            <img src={book} alt="book" className="rounded-xl" />
          </figure>
          <div className="card-body items-center text-center">
            <a
              className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
              href="https://drive.google.com/file/d/18CbdRDZXfeiKl0xEnVNs-8xD4O5b5F-d/view?usp=sharing"
            >
              {" "}
              <h2 className="card-title">Vol 1 - Issue 1</h2>
            </a>
          </div>
        </div>
        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Forthcoming Issue</h2>
          </div>

          <figure className="px-10">
            <img src={book2} alt="book2" className="rounded-xl" />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Vol 2- Issue 1</h2>
          </div>
        </div>
        <div className="card bg-base-100 shadow-sm mb-5">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Forthcoming Issue</h2>
          </div>

          <figure className="px-10">
            <img src={book3} alt="book3" className="rounded-xl" />
          </figure>
          <div className="card-body items-center text-center">
            <h2 className="card-title">Vol 2- Issue 2</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleDetailscopy;
