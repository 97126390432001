import React from "react";
import { Link } from "react-router-dom";

const ArticleNav = () => {
  return (
    <div className=" bg-[#0C608E] flex items-center justify-center h-[40px] text-white">
      <Link
        className="btn btn-ghost btn-xs normal-case text-sm "
        to="/articles/forthcoming"
      >
        Forthcoming Issues
      </Link>
      <Link
        className="btn btn-ghost btn-xs normal-case text-sm"
        to="/articles/currentissue"
      >
        Current Issue
      </Link>
      <Link
        className="btn btn-ghost btn-xs normal-case text-sm"
        to="/articles/allissue"
      >
        All Issues
      </Link>
      {/* <Link
        className="btn btn-ghost btn-xs normal-case text-sm"
        to="/articles/allarticles"
      >
        All Articles
      </Link> */}
    </div>
  );
};

export default ArticleNav;
