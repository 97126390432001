import React from "react";

const Editors = () => {
  return (
    <div className="max-w-[70%] mx-auto p-5">
      <div>
        <h3 className="text-2xl font-semibold text-center underline underline-offset-8">
          Role of Editors
        </h3>

        <p className="text-lg my-4 font-semibold">Editor-in-Chief</p>

        <ul className="list-disc">
          <li>Lead and direct the journal’s strategic goal and mission.</li>
          <li>
            Attract contents and ensure a supply of high-quality manuscripts in
            quantities that can maintain the journal’s publishing schedule.
          </li>
          <li>
            Monitor Editorial Board Members (timeliness, review quality and
            tone).
          </li>
          <li>
            Evaluate new submissions to determine whether it is within the
            journal’s scope and whether it appears to fulfill the interest
            criteria of the journal.
          </li>
          <li>
            Ensure that manuscripts adhere to the journal’s editorial policies,
            that the article type has correct content, and the English
            Language’s quality affects the ability to assess the scientific
            content.
          </li>
          <li>Make final publication decisions.</li>
          <li>
            Lead the selection of Editorial Board and chair all editorial
            meetings.
          </li>
          <li>
            Engage the Editorial Board continually on the journal’s progress and
            include them on ideas for editorial development.
          </li>
          <li>Lead the improvement of the journal’s impact and visibility.</li>
        </ul>

        <p className="text-lg mt-7 mb-4 font-semibold">
          Associate Editor/ Executive editors
        </p>

        <ul className="list-disc">
          <li>
            Manage the review process to ensure that manuscripts move through
            review and revision as efficiently as possible.{" "}
          </li>
          <li>
            Liaise with the Editor-in-Chief to identify suitable reviewers,
            obtain reviews, and make recommendations to the Editor-in-Chief
            based on the reviews received and their own assessment of the
            manuscript.
          </li>
          <li>
            Serve in the Editorial Board to uphold scholastic standards and
            provide input on journal policy, scope, and direction.
          </li>
          <li>
            Assist authors and reviewers with registration on Editorial Manager
            and troubleshoot problems with manuscript submission.
          </li>
          <li>
            Track and keep a meticulous record of manuscripts at different
            stages of peer review while keeping a close eye on reviewer and
            editor turnaround times.
          </li>
          <li>
            Tract editorial board members activities and facilitate
            communication with members.
          </li>
          <li>
            Ensure a publication agreement is received for all accepted
            manuscripts.
          </li>
          <li>
            May be designated by the Editor-in-Chief to act as a Handling Editor
            on relevant articles and oversee the review and editorial
            decision-making process.
          </li>
        </ul>

        <p className="text-lg mt-7 mb-4 font-semibold">Editorial Board</p>
        <ul className="list-disc">
          <li>Provide scientific expertise for the journal.</li>
          <li>Serve as peer reviewer on selected articles.</li>
          <li>
            Help the journal attract high-quality manuscripts by promoting the
            journal at relevant conferences and workshops.
          </li>
          <li>
            Provide feedback and suggestions for the improvements of the
            journal.
          </li>
          <li>
            Attend editorial meetings and assist in strategic decision-making.
          </li>
          <li>
            Suggest topics and authors for commissioned reviews and
            commentaries.
          </li>
          <li>
            May be designated by the Editor-in-Chief to act as a Handling Editor
            on relevant articles and oversee the review and editorial
            decision-making process.
          </li>
        </ul>
      </div>

      <div>
        <p className="text-lg my-10">
          The Associate Editor and Editorial Board members must notify the
          Editor-in-Chief immediately if they perceive or have a potential
          conflict of interest (e.g. if one of the authors is a close current
          collaborator or employed at the same institution) or if the topic is
          outside their area of expertise that they feel unqualified to handle
          the submission.
        </p>
      </div>
    </div>
  );
};

export default Editors;
