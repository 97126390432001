import React from "react";

const Contact = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-xl my-16">
        <p className="text-center">Publisher: Dr. Nusrat Subhan</p>
        <p className="text-center">
          Mailing Address: Dr. Nusrat Subhan and Son’s Science <br />
          House 229, Road 2, Block-B, Bashundhara, Dhaka, 1229
        </p>{" "}
        <p className="text-center">Phone- 01711972725, 01711386781.</p>
        <p className="text-center">Email- journalbep@gmail.com</p>
      </div>
    </div>
  );
};

export default Contact;
