import React from "react";

const Open = () => {
  return (
    <div className="max-w-[70%] mx-auto p-5">
      <h3 className="text-lg my-2 font-semibold">Open Access</h3>

      <p className="text-[16px]">
        One key request of researchers across the world is unrestricted access
        to research publications. Journal of Biosciences and Experimental
        Pharmacology is fully committed Open Access Initiative by providing free
        access to all articles (both Abstract and full PDF text) as soon as they
        are published. We ask you to support this initiative by publishing your
        papers in this journal.
      </p>
    </div>
  );
};

export default Open;
