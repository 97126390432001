import React from "react";
import { Link } from "react-router-dom";

const InformationNav = () => {
  return (
    <div className=" bg-[#0C608E] flex items-center justify-center h-[40px] text-white">
      <Link className="btn btn-ghost btn-xs normal-case text-sm " to="/info">
        Authors
      </Link>
      <Link
        className="btn btn-ghost btn-xs normal-case text-sm"
        to="/info/reviewers"
      >
        Reviewers
      </Link>
      <Link
        className="btn btn-ghost btn-xs normal-case text-sm"
        to="/info/editors"
      >
        Editors
      </Link>
      <Link
        className="btn btn-ghost btn-xs normal-case text-sm"
        to="/info/open"
      >
        Open Access
      </Link>

      <Link
        className="btn btn-ghost btn-xs normal-case text-sm"
        to="/info/advertising"
      >
        Advertising Policy
      </Link>
    </div>
  );
};

export default InformationNav;
