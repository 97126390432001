import React from "react";
import "./AboutJournal.css";
import book from "../../../images/book.png";

const AboutJournal = () => {
  return (
    <div className="grid  gap-x-5 gap-y-5 max-w-[90%] mx-auto my-12 p-5">
      <div className=" bg-white shadow-md p-5">
        <div>
          <h1 className="text-3xl underline underline-offset-4 decoration-red-950 my-4 text-center">
            Aim and Scope
          </h1>
        </div>
        <div>
          {/* <h2 className="text-xl font-semibold mt-10 mb-5">Aim and Scope</h2> */}
          <p className="text-[18px] mb-3 w-[100%]">
            The Journal of Biosciences and Experimental Pharmacology (JBEP) is
            an open-access journal that publishes high-quality research in the
            discipline. It is peer-reviewed. It is the first journal dedicated
            to study on the issues of data collecting, analysis, and reporting,
            with the express objective of fostering diversity and inclusion in
            all parts of its publication process and an international editorial
            board of top researchers. The JBEP believes that variety of thought
            and experience is critical for scientific progress and works hard to
            promote a friendly and inclusive environment for all authors,
            reviewers, and readers. We invite authors to submit papers that
            represent the breadth of human experience. We are especially
            interested in publications that address the health needs of
            marginalized populations and contribute to our understanding of the
            biology and pharmacological pathways that underpin disease in a
            variety of populations. We also encourage authors to utilize
            inclusive language and make their research accessible to a wide
            range of readers. We believe that diversity and inclusion are
            critical to the Journal of Biosciences and Experimental
            Pharmacology's (JBEP) mission of publishing high-quality research in
            the biosciences and experimental pharmacology. We gratefully
            recognize all authors, reviewers, and readers for their
            contributions to the JBEP, and we look ahead to expanding to promote
            diversity and inclusion in the field of biosciences and experimental
            pharmacology. The scope of articles are in all areas of
            Pharmaceutical Sciences including pharmaceutical drugs, community
            pharmacy, hospital pharmacy, clinical pharmacy, veterinary
            pharmacology, nuclear pharmacy, pharmaceutical analysis,
            pharmaceutical chemistry, pharmacy informatics, pharmaceutics,
            medicinal chemistry, pharmacognosy, pharmacotherapy, nutrition,
            clinical nutrition, pharmacodynamics, pharmacokinetics, clinical
            pharmacology, neuropharmacology, psychopharmacology,
            pharmacogenetics, pharmacogenomics, pharmacoepidemiology,
            toxicology, theoretical pharmacology, medicine development and
            safety testing, drug legislation and safety, pharmaceutical
            microbiology, pharmaceutical molecular biology, nanotechnology,
            pharmaceutical biotechnology, nutrition, clinical nutrition,
            biochemistry, chemistry, analytical chemistry, microbiology,
            veterinary science etc.
          </p>
        </div>

        <div>
          <h1 className="text-3xl underline underline-offset-4 decoration-red-950 my-10 text-center">
            Publication frequency
          </h1>
          <p className="text-[18px] w-[100%]">
            The Journal of Biosciences and Experimental Pharmacology (JBEP) are
            dedicated to publishing high-quality research in a timely and
            efficient manner, while providing equal chances to all authors. We
            understand the difficulties of the publishing process and endeavor
            to help authors every step of the way. We also realize that the
            frequency with which a journal publishes might affect individual
            authors in different ways. Authors in their early careers or from
            underrepresented groups, for example, may have fewer resources and
            less time to prepare and submit articles. We are committed to
            addressing these inequities, such as providing accelerated review
            alternatives for authors from underrepresented groups and clear and
            unambiguous instructions on the journal&#39;s submission and review
            process. We understand the importance of timely editorial review and
            publishing in scientific growth, and we are constantly striving to
            improve. Journal timelines vary depending on the subject area and
            article; we have publicly displayed our timings below as a guide,
            but please be aware that this may not reflect our most recent
            changes. This journal annually publishes two issues (June and
            December) under the continuous publication model beginning with
            Volume 1, Issue 1. This means that as soon as an article is ready to
            be published, it will be immediately assigned to an issue online
            rather than waiting for other articles to be completed, resulting in
            faster access to the final version.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutJournal;
