
const Advertising = () =>{


    return (
        <div className="w-[90%] mx-auto my-20">
        <h3 className="text-xl font-semibold mt-10 mb-5">
          Advertising Policy
        </h3>
        <p className="text-[18px] mb-3 w-[100%]">
          Journal of Biosciences and Experimental Pharmacology accepts
          advertisements that are relevant to the journal’s subject community,
          promote high quality products and services, and are provided by
          reputable organizations who display a true commitment to science and
          medicine. This journal can earn revenue from advertising sales
          income. The publishers advertising policy safeguards editorial
          autonomy and mitigates potential conflicts of interest. The
          publishers advertising sales activities conform to applicable ICMJE
          advertising recommendations, and all advertising campaigns served
          against editorial content are subject to the ICMJE recommendations.
          Our policies align with industry standards, guidelines, and best
          practices. Advertisers, agencies, and partners promoting their
          products on our platforms follow regulations set forth by the
          appropriate regional governing bodies. We targets advertising based
          on publication, taxonomy, geography, behavior, and opted-in audience
          demographics. User specific targeting only applies to individuals
          who have willingly and explicitly agreed to share their personal
          data as per our privacy policy. For more information about this
          journal’s advertising policy, please contact{" "}
          <strong className="text-[16px]">journabep@gmail.com.</strong> 
        </p>
      </div>
    )
}

export default Advertising;