import React from "react";

const FeesPayment = () => {
  return (
    <div className="my-10">
      <div className="max-w-[90%] mx-auto">
        <h1 className="text-center text-3xl font-semibold underline underline-offset-4 decoration-red-950 my-10">
          Fees and payments
        </h1>
        <div>
          <h3 className="text-xl font-semibold mt-10 mb-5">Open access</h3>
          <p className="text-[18px] mb-3 w-[100%]">
            The Journal of Biosciences and Experimental Pharmacology (JBEP) is
            an open access publisher with gold open status. All papers from our
            journal portfolio are immediately and permanently available online
            free of charge upon publication. Articles in the Journal of
            Biosciences and Experimental Pharmacology (JBEP) are published under
            a license that allows for free use, distribution, and reproduction
            in any medium, as long as the original authors and source are
            attributed. All articles will be assigned a unique Crossref DOI
            number (Digital Object Identifier), whereby they become searchable
            and citable without delay. The articles in Journal of Biosciences
            and Experimental Pharmacology are open access articles licensed
            under the terms of the Creative Commons Attribution 4.0
            International License, which permits unrestricted use, distribution,
            and reproduction in any medium, provided the original work is
            properly cited.
          </p>
          <h3 className="text-xl font-semibold mt-10 mb-5">
            Article Processing Fees
          </h3>
          <p className="text-[18px] mb-3 w-[100%]">
            Please note that publication fees apply only to the manuscripts
            accepted for publication in Asian Journal of Medical and Biological
            Research.{" "}
          </p>
          <p className="text-[18px] mb-3 w-[100%]">
            <strong>Inland: </strong> Authors of Bangladesh will be levied @ BD
            Tk. 20,000/- per accepted manuscript.
          </p>
          <p className="text-[18px] mb-3 w-[100%]">
            <strong>Foreign: </strong> Author(s) of manuscripts originating from
            outside Bangladesh have to pay @ US $ 250 for each accepted article.
          </p>
          <h3 className="text-xl font-semibold mt-10 mb-5">Payment Options</h3>
          <ul className="list-disc ml-5">
            <li>
              You can make your payment directly into our bank account (please
              check invoice) and e-mail the bank wire transfer receipt to us.
            </li>
            <li>
              If you want to make your payment through any other means, please
              write an e-mail to: journalbep@gmail.com. We will respond to you
              with further instructions on how you can pay the publication fee
              of an accepted article.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FeesPayment;
