import React from "react";
import "./Author.css";

const Authors = () => {
  return (
    <div className="max-w-[70%] mx-auto p-5 font-container">
      <div>
        <h3 className="text-2xl font-semibold text-center underline underline-offset-8">
          INFORMATION FOR AUTHORS
        </h3>

        {/* <ul className='list-disc'>
                    <li>A correspondent is recommended to be a submitter. In some cases, the representative author might be the submitter.</li>
                    <li>The authors' instructions should be read carefully before preparing the manuscript.</li>
                    <li>Provided abstract meets specified word numbers (not exceeding 240 words for Original Article and Review Article/Short Communication).</li>
                    <li>The cover letter should be prepared (should be inserted in the submission step).</li>
                    <li>The title page and the rest of the manuscript text should be prepared in the same file. Figure files (maximum 7-8, TIFF or JPEG), Figure legends (detailed), and Tables should be inserted immediately after the cited text in the main manuscript file. Tables and Figures with 300dpi JPG or TIFF files should also be uploaded separately in the submission system. The results section is to be separated from the discussion. The result section is also to be divided into subsections using subtitles.</li>
                </ul> */}

        <p className="text-lg my-4 font-semibold">Guide for Authors</p>

        <h3 className="text-[16px]">
          Journal of Biosciences and Experimental Pharmacology is a
          peer-reviewed journal that covers all aspects of Life sciences.
          Categories of contribution for the journal are Communications, Full
          length Research article, Review articles, and Progress reports. All
          types of articles should represent novel research and significant
          advances in the field of life science. Only manuscripts in English are
          accepted. Contributors should bear in mind the interdisciplinary
          nature of the readership, always emphasizing the importance of the
          topic to the life science research community on the whole. Reprints
          and PDF files can be ordered when the proofs of a manuscript are
          returned to the publisher. Reprint rates are available from the
          Editorial Office on request.
        </h3>
      </div>

      <div>
        <p className="text-lg my-4 font-semibold">Manuscript Submission</p>
        <text className="text-[16px]">
          The manuscript should be submitted online via e-mail to editor or the
          online submission service. In order to submit a manuscript you will
          need a single Microsoft Word, RTF, or PDF file that contains the text,
          all figures and tables, and any Supporting Information associated with
          the manuscript. MS Word templates for Research article, Reviews,
          Progress Reports, and Communications are available on request to
          editor by e-mail or the homepage in the section &quot;Author
          Guidelines&quot;. Please use these templates for submission of your
          contribution. Journal of Biosciences and Experimental Pharmacology
          does not publish manuscripts that have already appeared in print or
          electronically (including those deposited in preprint archives). The
          author must inform the editor of manuscripts submitted, soon to be
          submitted, or in press at other journals that have a bearing on the
          manuscript being submitted to Journal of Biosciences and Experimental
          Pharmacology. In particular, authors should reveal all sources of
          funding for the work presented in the manuscript and should declare
          any conflict of interest. To ensure continuity of contact details, the
          corresponding author of a manuscript should hold a permanent academic
          and or research position. Exceptions may be made at the discretion of
          the Editorial Office.
        </text>
      </div>

      <div>
        <p className="text-lg my-4 font-semibold">
          Confirmation of Receipt/Assessment
        </p>
        <text className="text-[16px]">
          Authors of all articles will receive acknowledgement of receipt of
          their manuscript (by e mail). All contributions are subject to
          assessment by the editors and/or refereeing.
        </text>
      </div>

      <article>
        <p className="text-lg my-4 font-semibold">Manuscript for Production</p>
        <text className="text-[16px]">
          If a manuscript is accepted, the file supplied at manuscript
          submission (or revision if applicable) will be used for production.
          The data therefore must be the FINAL version. Costs for any additions
          or further changes made during correction of the page proofs may be
          charged to the author. Tables should be included in the text files and
          follow the example laid out in the template. Vector graphic images
          such as plots, graphs, and line diagrams (including chemical
          structures) should be imported into a Word file. Original files of
          graphical items prepared using ChemDraw or Photoshop may also be
          included. Bitmap graphic images such as photographs and electron
          microscope images should be saved as TIFF files; each figure part must
          have a resolution of at least 300 dpi (1000 pixels wide when the image
          is printed at a single column width). Supporting Information in the
          form of additional figures, experimental details, and so on may
          accompany a manuscript, and will be published online only. This
          material will not be edited, so should be error free. Supporting
          Information pages must be prepared as a single MS Word document and
          should be submitted as a separate file with all figures incorporated
          into the text.
        </text>
      </article>

      <div>
        <p className="text-lg my-4 font-semibold">
          Guidelines for the Preparation of Manuscripts
        </p>
        <p className="text-[16px]">
          Authors are requested to take special care with the following points
          when preparing manuscripts for Journal of Bioscience and
          Pharmaceutics:
        </p>

        <ul className="text-[15px] list-decimal	ml-5">
          <li className="my-5">
            Manuscripts should be typed with double-line spacing (about 60
            characters per line and about 26 lines per page; large script; Greek
            letters in the character font Symbol; special characters must be
            clearly recognizable; sub- or superscripts, italics, or boldface
            should be clearly distinguishable); this holds for all parts of the
            manuscript, including those that will be printed in smaller type,
            and also for the printed version of an electronic manuscript. Page
            Layout should be A4 size (8.27” x 11.69”) of each page. All pages,
            including those with the references, tables, and legends, must be
            numbered (Page: 1, Page: 2…) consecutively. American-style English
            spelling should be used throughout the manuscript.
          </li>
          <li className="my-5">
            The title, which should be as succinct as possible. The first
            letters of all words, except coordinating conjunctions, articles,
            and prepositions, should be capitalized. No references should be
            Then follows the first name, other initials, and surname of each
            author, and an asterisk to indicate each corresponding author. Use
            digit superscript number (X 1 , Y 2 , Z 3 …) to indicate the
            affiliation(s) of the author(s). The names of all authors according
            to research group (with academic title and all first names as
            initials), the complete postal address, and e-mail address of the
            corresponding author(s) appear after the author byline. For all
            other authors, only the address of their academic institution or
            company is required. used.
          </li>
          <li>
            References to the literature or to footnotes in the text are typed
            in square [ ] brackets after any punctuation. In the references
            section, these are numbered (1, 2, 3…) consecutively and listed
            (with the numbers in square brackets not as superscripts) at the end
            of the main body of text. In references section the Citation styles
            would be “The Chicago Manual of Style” format. <br />
            <p className="text-[14px] font-semibold mt-3">
              Example for book citation:
            </p>
            [1] Author A, Author B. Name of Book. Volume No. Publisher name,
            Year of publication. Remington, Joseph Price. Remington: the science
            and practice of pharmacy. Vol. 1. Lippincott Williams &amp; Wilkins,
            2006.
            <p className="text-[14px] font-semibold mt-3">
              Example for journal citation:
            </p>
            [2] Author A, Author B. “Title of article”. Name of Journal, Volume
            No, Year of publication, Page No. Kim, Sung Han, Jung Eun Lee,
            Shazid Md Sharker, Ji Hoon Jeong, Insik In, and Sung Young Park.
            &quot;In vitro and in vivo tumor targeted photothermal cancer
            therapy using functionalized graphene nanoparticles.&quot;
            Biomacromolecules 16, no. 11 (2015): 3519-3529.
            <p className="text-[14px] font-semibold mt-3">
              Example for website citation:
            </p>
            [3] The most basic entry for a website consists of the author
            name(s), page title, website title, web address, and date accessed.
            Last Name, First Name. ‘Page Title.’ Website Title. Web Address
            (retrieved Date Accessed). Smith, John. “Obama inaugurated as
            President.” CNN.com.
            http://www.cnn.com/POLITICS/01/21/obama_inaugurated/index.html
            (accessed February 1, 2009). They should not contain comprehensive
            experimental details (which should be included in the Experimental
            Section instead) or long explanatory text.
          </li>
          <li className="my-5">
            Tables should be provided with a brief legend (use the word “Table”
            throughout the text unabbreviated) and should only be subdivided by
            three horizontal lines (head rule, neck rule, foot rule). Tables
            with only one or two columns and columns with only one or two
            entries are to be avoided. For footnotes in Tables, Roman lowercase
            letters followed by a closing parenthesis are to be used. All tables
            are to be numbered (Arabic numerals) in the sequence in which they
            are referred to in the text. Physical data for several compounds
            should be summarized in a Table; otherwise, a footnote is
            sufficient.
          </li>

          <li className="mb-5">
            Each figure and scheme should have a caption, and these should be
            listed together in numerical order after the references. All figures
            and schemes should be mentioned in the text in numerical order. For
            peer-review, it is preferred to have the figures and schemes appear
            as they are mentioned in the text. Different types of atoms in
            structural chemistry figures should be clearly distinguishable (by
            different graphical shading). Use the full word &quot;Figure&quot;
            in all parts of the manuscript. Symbols of physical quantities, but
            not their units (e.g., T (for temperature, in contrast to T for the
            unit Tesla), but K as unit; J, but Hz; a, but nm), stereochemical
            information (cis, E, R, etc.; D), locants (N-methyl), symmetry
            groups and space groups (C 2v ), and prefixes in formulae or
            compound names such as tBu and tert-butyl must be in italics, and
            formula numbers in boldface (Arabic numerals and, if necessary,
            Roman lowercase letters). Labels of axes are to be separated from
            their units by a slash: e.g., T/K; the ordinate should be labeled
            parallel to the axis.
          </li>
          <li className="mb-5">
            Equations should be labeled with consecutive number and mentioned by
            label in the text, for example, “Equation (1).
          </li>
          <li className="mb-5">
            Physical data should be quoted with decimal points and negative
            exponents (e.g., 25.8 J K −1 mol −1 ), and arranged as follows where
            possible — but in any event in the same order within the manuscript.
          </li>
          <li className="mb-5">
            <strong className="font-semibold">
              {" "}
              Nomenclature, symbols, and units:
            </strong>{" "}
            The rules and recommendations of the International Union of Pure and
            Applied Chemistry (IUPAC), the International Union of Biochemistry
            (IUB), and the International Union of Pure and Applied Physics
            (IUPAP) should be adhered to. The Latin names of biological species
            and genera should be written in italics, with the full name always
            used when it appears for the first time in the text (e.g., Homo
            sapiens); the abbreviated form (e.g., H. sapiens) may be used
            thereafter.
          </li>
          <li className="mb-5">
            <strong className="font-semibold">
              Supporting Information and Acknowledgements:
            </strong>{" "}
            Supporting Information and Acknowledgements: A Supporting
            Information notice and the Acknowledgements can be inserted after
            the main text and experimental section of the
          </li>
          <li className="mb-5">
            <strong className="font-semibold">
              {" "}
              Manuscripts containing animal experiments
            </strong>{" "}
            must include a statement in the Experimental Section to state that
            permission was obtained from the relevant national or local
            authorities. The institutional committees that have approved the
            experiments must be identified and the accreditation number of the
            laboratory or of the investigator given where applicable. If no such
            rules or permissions are in place in the country where the
            experiments were performed, then this must also be clearly stated.
          </li>
          <li className="mb-5">
            <strong className="font-semibold">
              Manuscripts with experiments with human subjects or tissue samples
              from human
            </strong>
            subjects must contain a disclaimer in the Experimental Section to
            state that informed signed consent was obtained from either the
            patient or from next of kin.
          </li>
        </ul>
      </div>

      <div>
        <h3 className="text-xl font-semibold mt-10 mb-5">
          Potential Conflicts of Interests
        </h3>
        <p className="text-[18px] mb-3 w-[100%]">
          A conflict of interest statement should include any financial or other
          support for the study, writing or revising the manuscript, or any
          factor that limited (or will limit in the future) the investigators'
          full access to the data and its analysis. The Journal of Biosciences
          and Experimental Pharmacology (JBEP) requires all authors to complete
          the journal's unified disclosure form. Each author is responsible for
          the truth and completeness of the material supplied and should
          complete a separate form to report any potential conflicts of
          interest. Using the forms completed by each author, the corresponding
          author should produce a conflict-of-interest statement for inclusion
          in the paper. The COI declaration contained in the manuscript is
          adequate for the initial submission. If the manuscript is accepted for
          publication, all authors must complete the (JBEP) unified disclosure
          forms. The JBEP’s form could be downloaded here All editors,
          reviewers, and editorial staff of the journal must immediately report
          any potential conflicts of interest, whether financial, professional,
          personal, or otherwise, to the journal's editorial office. The
          following are examples, but not exhaustive: Financial interest in the
          study's findings Competition for the publication of the same findings
          Recent collaboration or publishing with an author Working at the same
          university as an author Having a disagreement with an author Being an
          author's friend, family member, current or past mentor This guideline
          applies to all journal team members, regardless of their job. Any
          submissions from editors, members of the editorial board, or journal
          personnel will go through the same rigorous peer-review procedure as
          other submissions and will be considered and treated the same. The
          Editor-in-Chief, Associate Editor, Editorial Board Member, and
          Editorial Staff will not make decisions on, handle, or examine their
          own submissions in order to ensure an unbiased review. All reasonable
          precautions will be taken to avoid any potential conflict of interest
          in the handling of such manuscripts at all levels, including the
          assignment of a handling editor, reviewer selection, decision-making,
          and, if necessary, publishing processing.
        </p>
      </div>

      <div>
        <h3 className="text-xl font-semibold mt-10 mb-5">Copyright</h3>
        Copyright Notice License and Copyright Agreement In submitting the
        manuscript to the journal, the authors certify that:
        <ul className="text-[18px] mb-3 w-[100%]">
          <li>
            They are authorized by their co-authors to enter into these
            arrangements.The work described has not been formally published
            before, except in the form of an abstract or as part of a published
            lecture, review, thesis, or overlay journal.
          </li>

          <li>
            That it is not under consideration for publication elsewhere, That
            its publication has been approved by all the author(s) and by the
            responsible authorities – tacitly or explicitly – of the institutes
            where the work has been carried out.
          </li>
          <li>
            They secure the right to reproduce any material that has already
            been published or copyrighted elsewhere.
          </li>
        </ul>
        <ul>
          They agree to the following license and copyright agreement. Copyright
          Authors who publish with Journal of Biosciences and Experimental
          Pharmacology&#39;s (JBEP) agree to the following terms:
          <li>
            1. Authors retain copyright and grant the journal right of first
            publication with the work simultaneously licensed under CCBY  that
            allows others to share the work with an acknowledgment of the
            work&#39;s authorship and initial publication in this journal. 
          </li>
          <li>
            2. Authors are able to enter into separate, additional contractual
            arrangements for the non- exclusive distribution of the
            journal&#39;s published version of the work (e.g., post it to an
            institutional repository or publish it in a book), with an
            acknowledgment of its initial publication in this journal.
          </li>
          <li>
            3. Authors are permitted and encouraged to post their work online
            (e.g., in institutional repositories or on their website) prior to
            and during the submission process, as it can lead to productive
            exchanges, as well as earlier and greater citation of published work
          </li>
        </ul>
      </div>

      <div>
        <h3 className="text-xl font-semibold mt-10 mb-5">Ethical Issues</h3>
        <p className="text-[18px] mb-3 w-[100%]">
          The inclusion of ethics approval and informed consent declarations is
          a vital need for research publications, and each managing Editor bears
          a significant amount of responsibility in this regard. The inclusion
          of this information in a uniform format in every submission also helps
          abstracting and indexing services evaluate journals. Every
          publication, including non-research papers, must include relevant
          statements on the following topics, according to standard policy for
          journals publishing human and/or animal studies:
        </p>
        <ul>
          <li>
            1. Ethical review: Depending on the type of research, this may
            require approval from an ethics committee, institutional review
            board (IRB), or institutional animal care and use committee (IACUC).
          </li>
          <li>
            2. Informed consent: This is the process of getting
            participants&#39; agreement to take part in a research project and
            to utilize their data or samples. Animal welfare must be considered
            in the context of animal studies.
          </li>
        </ul>
        <ul className="list-disc ml-5 mt-5">
          Transparency and completeness are critical when it comes to what
          constitutes good ethical and consent/animal welfare statements.
          Editors should be aware of the following:
          <li className="mt-5">
            If ethics permission was obtained, provide the name of the approving
            body as well as the approval number/ID in the manuscript. Authors
            should add more information than, say, &quot;Approval was obtained
            from the local ethics committee.&quot;
          </li>
          <li>
            If an appropriate body has granted an exemption from ethics
            approval, this should be disclosed, together with the basis for the
            exemption.
          </li>
          <li>
            Manuscripts should include statements that explain why ethics
            approval and/or informed consent were not sought for a particular
            study in a specific country or region. Statements like &quot;Ethics
            approval was not required for this study&quot; or &quot;Not
            applicable&quot; do not provide enough detail; appropriate
            justifications, as well as citations when applicable, should be
            included.
          </li>
          <li>
            When informed consent is obtained, the purpose of the consent should
            be mentioned. For example, consent for treatment does not always
            include permission to utilize samples and/or participate in a
            retrospective study. Furthermore, unless otherwise noted, consent
            for treatment is distinct from consent for publication of patient
            information (including photographs) in a case report.
          </li>
          <li>
            It should be mentioned whether informed consent was written or
            verbal, and why (together with how the consent was recorded).
          </li>
          <li>
            Where applicable, authors should disclose who waived the requirement
            for informed consent and the explanation.
          </li>
          <li>
            It is standard JBEP policy to require informed consent for the
            publication of case reports and case series deemed not to constitute
            research, except in exceptional circumstances or when waived by
            ethics committee/IRB or other authorized body. Even then, for
            completeness, Editors may wish to request consent for publication
            before considering a case report or non-research case series.
          </li>
          <li>
            Animal welfare statements should confirm that the study followed
            international, national, and/or institutional guidelines for humane
            animal treatment and complied with relevant legislation; that it
            involved client-owned animals and demonstrated a high standard (best
            practice) of veterinary care with informed client consent; or that
            guidelines for humane animal treatment did not apply to the current
            study, including the reason.
          </li>
        </ul>
      </div>

      <div className="mt-10">
        <p className="text-xl font-semibold mt-10 mb-5">Electronic Proofs</p>
        <p className="text-[18px] mb-3 w-[100%]">
          One of the corresponding authors will receive galley proofs. As a
          corresponding author, the contacted author is responsible for ensuring
          that all necessary authors are aware the proofs are ready for review.
          Please avoid having multiple authors access the proofs simultaneously.
          Corrections should be returned to the editor within three days.
        </p>
      </div>

      <div>
        <p className="text-xl font-semibold mt-10 mb-5">Reprint Permission</p>
        <p  className="text-[18px] mb-3 w-[100%]">
          If you are requesting for permission to reproduce book
          chapters/articles, excerpts, image/photo/illustration/table/figure
          published by Journal of Biosciences and Experimental
          Pharmacology&#39;s (JBEP) unless the requested materials is under CC
          BY license: 1. Please contact us at  at  journalbep@gmail.com for the
          below mentioned requests: o permission to reproduce our content in
          your print and e-course pack use o permission to upload our
          copyrighted material onto your institutional repository site o request
          for electronic text files for students with disability
        </p>
      </div>
    </div>
  );
};

export default Authors;
