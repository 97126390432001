import React from "react";

const Reviewers = () => {
  return (
    <div className="max-w-[70%] mx-auto p-5">
      <div>
        <h3 className="text-2xl font-semibold text-center underline underline-offset-8">
          For reviewers
        </h3>


  

        <h3 className="text-lg my-2 font-semibold">Invitation to Review</h3>

        <p className="text-[16px]">
          Journal of Biosciences and Experimental Pharmacology is looking for
          qualified reviewers as members of its review board team. Journal of
          Biosciences and Experimental Pharmacology will serve as a great
          resource for researchers and students across the globe. We ask you to
          support this initiative by joining our Reviewer's team. If you are
          interested to serving as a reviewer, please send us your resume.
         <a className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="https://docs.google.com/document/d/1p0EwR-7Xhk3o3xIvzDOq7pbEq32hU7_I/edit?usp=sharing&ouid=110316416725613755975&rtpof=true&sd=true"> Download The review form</a>
        </p>

        {/* <h3 className="text-lg my-2 font-semibold">Publication Alert</h3>

        <p className="text-[16px]">
          We will be glad to send you a publication alert showing the table of
          content with link to the various Abstracts and full PDF text of
          articles published in each issue.
        </p> */}
      </div>
      
      <div>
        <h3 className="text-lg my-4 font-semibold"> Peer Review Process</h3>
        <p className="text-[16px]">
          Articles submitted to Journal of Biosciences and Experimental
          Pharmacology, are subject to strict peer-reviewing. Once a manuscript
          passes the initial quality checks, it will be assigned to at least two
          independent experts of the desired field for peer-review. The journal
          operates a single blind peer-review (the reviewers know the authors’
          identities, but the authors do not know the reviewers’ identities).
          Key features of peer review process are listed below: <br/> All research
          articles are reviewed by at least two suitably qualified experts. A
          reviewer that accepts the invitation will be given 14 days to complete
          a review. After 14 days the review becomes overdue. If the reviewer
          doesn’t respond to the personal email, the manuscript invitation will
          be withdrawn and the next reviewer will be invited.  The
          corresponding author will receive an email once reviewers submit the
          comments to the Journal of Biosciences and Experimental Pharmacology.
          The Editor in Chief will check the comments and forward it to the
          corresponding author with or without further comments. The
          corresponding author has to submit the revised manuscript as per
          editorial and reviewers comments within two weeks. If the
          corresponding author does not submit the revised version within two
          weeks of sending the revision letter, the article will be considered
          rejected, and the article metadata will be deleted from the system.<br/>
          If necessary, a revised manuscript will be sent to the reviewers who
          initially reviewed the article. <br/> All publication decisions are made
          finally by the journal’s Editor-in-Chief or a committee of suitable
          editorial board member on the basis of the reviews provided. <br/> If all
          processes from complete submission to revision go smoothly, then 6-8
          weeks are usually required to reach a final decision
          (Acceptance/Rejection).
        </p>
      </div>
      

      <div>
        <h3 className="text-lg my-4 font-semibold">Policy review</h3>
        <p className="text-[16px]">
          All submissions to The Journal of Biosciences and Experimental
          Pharmacology (JBEP) are subjected to a thorough peer review procedure
          before publication. This procedure include reviewing the article with
          input from the writers from two or more subject-matter experts. Most
          of the time, neither the authors nor the reviewers are familiar with
          one another. A single-blind peer review procedure is what is being
          used here. JBEP, however, promotes participation in an open peer
          review process by authors and reviewers. Therefore, it is advised for
          reviewers to identify themselves to the writers while submitting their
          reviews. Even if the item is rejected, if a reviewer agrees to share
          their name, their personal profile page will publicly acknowledge that
          they conducted a review. Reviewers whose names were provided will be
          listed alongside the article itself if it is accepted. Every review of
          a JBEP-published article is available to the public. The manuscript
          files, peer reviewer feedback, author refutations, and amended
          materials are all included in this. JBEP thinks there are several
          benefits to open peer review. First off, knowing that their
          assessments will be made public encourages reviewers to provide
          insightful and constructive criticism, which can enhance the quality
          of peer reviews. Second, open peer review can serve to make the peer
          review process more transparent, which can boost reader, author, and
          reviewer confidence. Third, as reviewers are more inclined to engage
          if they believe their identities will be kept private, open peer
          review can serve to foster diversity and inclusion in the peer review
          process.
        </p>
      </div>
    </div>
  );
};

export default Reviewers;
