import React from "react";
import { Link } from "react-router-dom";

const Announcement = () => {
  return (
    <div className="article w-[70%] mx-auto my-10">
      {/* <h3 className="text-3xl underline underline-offset-8 font-semibold text-center py-5">News and Announcements</h3>
            <div className='article-area'>
                <div className='leftside shadow-lg bg-white my-4'>
                    <div>
                        <div className="my-1">
                            <span className="border-solid border-[1px] rounded border-black p-1 text-xs">Original article</span>
                        </div>

                        <div>
                            <a href="">
                                <h3 className='artTitle text-xl font-semibold hover:underline hover:text-[#7FB2C8]'></h3>
                            </a>
                        </div>

                        <div className="text-sm text-[#313742] my-1">
                            <span>2023, Vol6 Issue2
                                </span>
                        </div>

                        <div className="artInfo">
                            <span> { } </span>
                        </div>

                        <div className="artAccess">
                            <span>

                            </span>
                            <span>

                            </span>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <div className="text-4xl py-10 text-center h-[500px] flex items-center justify-center"></div>
      <div>
        <h3 className="text-lg my-4 font-semibold">Call for Paper</h3>
        <p className="text-[16px]">
          The Journal of Biosciences and Experimental Pharmacology provide rapid
          publication of articles in all areas of Pharmaceutical Sciences. The
          Journal welcomes submission of manuscripts that meet the general
          criteria of significance and scientific excellence. Papers will be
          published approximately one month after acceptance. Journal of
          Biosciences and Experimental Pharmacology will be published twice a
          year and all articles are peer-reviewed. The following types of papers
          are considered for publication:
        </p>
        <ul className="list-disc ml-5">
          <li>Original articles on basic and applied research.</li>
          <li>Critical reviews, surveys, opinions, commentaries and essays.</li>
        </ul>
        <p className="text-[16px] mt-2">
          Our objective is to inform authors of the decision on their
          manuscript(s) within four weeks of submission. Following acceptance, a
          paper will normally be published in the next issue.
        </p>
      </div> */}

      <div>
        <p className="text-2xl font-bold mt-5 mb-10">
          Volume 1, Issue 1 of Journal of Biosciences and Experimental
          Pharmacology has been published. <br /> Please see the table of
          content below the link:{" "}
          <a
            className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            href="https://drive.google.com/file/d/18CbdRDZXfeiKl0xEnVNs-8xD4O5b5F-d/view?usp=sharing"
          >
            Vol 1 - Issue 1
          </a>
        </p>
      </div>

      <p className="text-2xl font-bold my-10">
        Next issue will be available on June 2024.
      </p>

      <div>
        <h3 className="text-lg my-4 font-semibold">Call for Paper</h3>
        <p className="text-[16px]">
          The Journal of Biosciences and Experimental Pharmacology provide rapid
          publication of articles in all areas of Pharmaceutical Sciences. The
          Journal welcomes submission of manuscripts that meet the general
          criteria of significance and scientific excellence. Papers will be
          published approximately one month after acceptance. Journal of
          Biosciences and Experimental Pharmacology will be published twice a
          year and all articles are peer-reviewed. The following types of papers
          are considered for publication:
        </p>
        <ul className="list-disc ml-5">
          <li>Original articles on basic and applied research.</li>
          <li>Critical reviews, surveys, opinions, commentaries and essays.</li>
        </ul>
        <p className="text-[16px] mt-2">
          Our objective is to inform authors of the decision on their
          manuscript(s) within four weeks of submission. Following acceptance, a
          paper will normally be published in the next issue.
        </p>
      </div>
    </div>
  );
};

export default Announcement;
