import React from "react";
import "./JournalInfo.css";
import img6 from "../../../images/img-6.jpg";

const JournalInfo = () => {
  return (
    <section className="py-7">
      <div className="bg-[#FAF8FF] grid grid-cols-1 lg:grid-cols-5 ">
        <div className="col-span-2 flex flex-col items-start justify-start mt-10">
          <img
            className="w-[70%] lg:w-[80%] mx-[auto] shadow-lg border rounded-md"
            src={img6}
            alt="img-6"
          />
        </div>

        <div className="w-[80%] mx-auto mt-5 lg:col-span-3">
          <h1 className="text-2xl font-semibold">
            Journal of Biosciences and Experimental Pharmacology{" "}
          </h1>
          <div className="issn">ISSN- 2960-284X</div>
          <p className="text-lg text-justify">
            Journal of Biosciences and Experimental Pharmacology (JBEP) – is an
            open-access, international, peer-reviewed, scientific journal
            publishing original research findings and reviews on all aspects of
            biosciences and experimental pharmacology. JBEP is peer reviewed
            online journal which is also available in print version. The motto
            behind the journal is to provide a platform for Scientists and
            Academicians all over the world to promote, share, and discuss
            various new issues and developments in the entire arena of
            pharmaceutical and biosciences. The journal will also help students,
            researchers and scientist worldwide to benefit from the high quality
            peer reviewed articles. <br />
            <strong className="mt-5">Abbreviation:</strong> J. Bio. Exp. Pharm.{" "}
            <br />
            <strong>Frequency of publication:</strong>
              {" "}Two times a year (June, and December) in the English language. We
            also offer special issues on emerging topics. Notably, accepted
            papers will be published continuously in the journal (as soon as
            accepted)
            <p></p>
          </p>
        </div>
      </div>

      <div className="mt-20 text-justify w-[80%] mx-auto">
        <h1 className="text-2xl font-semibold text-center mb-6 underline offset-1">
          Message from the Chief Editor
        </h1>
        <p className="text-lg mb-3">
          Welcome to the inaugural volume and issue of the Journal of
          Biosciences and Experimental Pharmacology (JBEP). We are pleased to
          share with our readers six brand-new papers from writers who have
          kindly shared their research findings on a range of pharmacy and
          pharmacology-related topics in this issue. The biosciences and
          pharmacology are very dynamic fields. For the creation of new drugs
          and drug delivery systems, research and development of new
          instruments, strategies, and processes are needed. Research conducted
          by academicians at different universities bridges the gap between
          industrial requirements for drug development and drug delivery systems
          and those of the academic curriculum.
        </p>

        <p className="text-lg mb-2">
          Papers in the areas of pharmaceutical formulation development,
          phytochemistry, nanodrug delivery, and pharmaceutical analysis are
          included in this issue. Despite our best efforts, some of the articles
          and papers could not be included in the current edition due to
          decisions made by the editorial board and the referee review board.
          None of the writers should be discouraged from submitting their
          original works, case studies, research reviews, or empirical
          contributions to our journal in the future, though.{" "}
        </p>

        <p className="text-lg text-justify">
          {" "}
          We would like to express our gratitude to all of our authors for
          making this issue possible as well as to our reviewers for helping us
          guarantee that JBEP is of the high caliber we aim to maintain. We
          sincerely hope that all parties involved—from authors to
          reviewers—will keep firmly backing our publication.
          
          <p className="mt-5 text-lg">
            Dr. Md Ashraful Alam <br />
            Chief Editor <br />
            Journal of Biosciences and Experimental Pharmacology
          </p>
        </p>
      </div>
    </section>
  );
};

export default JournalInfo;
